// const startOfDay = (date: string) => {
//   return new Date(date).setHours(0, 0, 0, 0)
// }

// const endOfDay = (date: string) => {
//   return new Date(date).setHours(23, 59, 59, 999)
// }

export const eventsQueryDateTimeStart = ( dateStart: any, dateEnd: any ) => {
  return dateStart && !dateEnd ? {
    $or: [
      {
        // within day
        $and: [
          {
            date_time_start: {
              $gte: startOfDay(dateStart) || null
            }
          },
          {
            date_time_end: {
              $lte: endOfDay(dateStart) || null
            }
          },
        ]
      },
      {
        // within date range
        $and: [
          {
            date_time_start: {
              $lte: startOfDay(dateStart) || null
            }
          },
          {
            date_time_end: {
              $gte: startOfDay(dateStart) || null
            }
          },
        ]
      }
    ]
  } : {}
}

export const eventsQueryDateRangeFilter = ( dateStart: any, dateEnd: any  ) => {
  return dateStart && dateEnd ? {
    $or: [
      // same or smaller than the selected date range
      {
        $and: [
          {
            date_time_start: {
              $gte: startOfDay(dateStart) || null
            }
          },
          {
            date_time_end: {
              $lte: endOfDay(dateEnd) || null
            }
          },
        ]
      },
      // within the selected date range
      {
        $and: [
          {
            date_time_start: {
              $lte: endOfDay(dateStart) || null
            }
          },
          {
            date_time_end: {
              $gte: startOfDay(dateEnd) || null
            }
          },
        ]
      },
      // starts before the selected date range and ends within the selected date range
      {
        $and: [
          {
            date_time_start: {
              $lte: endOfDay(dateStart) || null
            }
          },
          {
            date_time_start: {
              $gte: startOfDay(dateEnd) || null
            }
          },
          {
            date_time_end: {
              $lte: endOfDay(dateEnd) || null
            }
          },
        ]
      },
      // starts within the selected date range and ends after the selected date range
      {
        $and: [
          {
            date_time_start: {
              $lte: endOfDay(dateStart) || null
            }
          },
          {
            date_time_end: {
              $gte: startOfDay(dateStart) || null
            }
          },
          {
            date_time_end: {
              $lte: endOfDay(dateEnd) || null
            }
          },
        ]
      },
      // starts on dateEnd and ends after the selected date range
      {
        $and: [
          {
            date_time_start: {
              $gte: startOfDay(dateStart) || null
            }
          },
          {
            date_time_start: {
              $lte: endOfDay(dateEnd) || null
            }
          },
          {
            date_time_end: {
              $gte: startOfDay(dateEnd) || null
            }
          },
        ]
      },
    ]
  } : {}
}

export const eventsQueryCurrentAndUpcomingFilterParams = ( ) => {
  return {
    $or: [
      {
        $and: [
          { date_time_start: { $gte: new Date().toISOString() } },
          { date_time_end: { $gte: new Date().toISOString() } },
        ]
      },
      {
        $and: [
          { date_time_start: { $lte: new Date().toISOString() } },
          { date_time_end: { $gte: new Date().toISOString() } },
        ]
      },
      {
        $and: [
          { date_time_start: { $gte: new Date().toISOString() } },
          { date_time_end: { $null: true } },
        ]
      },
    ]
  }
}

export const eventsQueryPastFilterParams = ( ) => {
  return {
    $or: [
      {
        $and: [
          { date_time_start: { $lte: new Date().toISOString() } },
          { date_time_end: { $lte: new Date().toISOString() } },
        ]
      },
      {
        $and: [
          { date_time_start: { $lte: new Date().toISOString() } },
          { date_time_end: { $null: true } },
        ]
      }
    ]
  }
}
